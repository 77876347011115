import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryLink', 'selectedCountry', 'locationButton']

  connect () {
    this.countryLinks = this.countryLinkTargets
    this.selectedCountry = this.selectedCountryTarget
    this.locationButton = this.locationButtonTarget

    this.countryLinks.forEach((link) => {
      link.addEventListener('click', this.selectCountry.bind(this))
    })

    this.locationButton.addEventListener(
      'click',
      this.redirectToCountry.bind(this)
    )
  }

  selectCountry (event) {
    event.preventDefault()
    const newLocaleText = event.currentTarget.textContent.trim()
    const newHref = event.currentTarget.getAttribute('href')

    this.selectedCountry.textContent = newLocaleText
    this.locationButton.setAttribute('data-href', newHref)
  }

  redirectToCountry () {
    const redirectUrl = this.locationButton.getAttribute('data-href')
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }
}
